<template>
  <base-source-date></base-source-date>
</template>

<script>
import BaseSourceDate from "@/views/admin/BaseSourceDate";
export default {
  name: "DatabasesStats",
  components: {BaseSourceDate}
}
</script>

<style scoped>

</style>